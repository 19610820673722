import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["map", "site", "search_field"];

  connect() {
    this.initMap();
  }

  initMap() {
    let location = { lat: 46.0, lng: 2.0 };
    var map = new google.maps.Map(this.mapTarget, {
      center: location,
      zoom: 8,
      mapId: "116b054690b368d8",
    });
    var search_field = document.getElementById("search_field");
    var search_url =
      document.getElementById("search_url").attributes["data-url"].value;

    const options = {
      fields: ["address_components", "geometry", "icon", "name", "place_id"],
    };
    var autocomplete = new google.maps.places.Autocomplete(
      search_field,
      options
    );
    autocomplete.addListener("place_changed", onPlaceChanged);
    var markers = [];
    map.addListener("idle", () => {
      deleteMarkers();
      getMarkers(
        map.getCenter().lat(),
        map.getCenter().lng(),
        map.zoom,
        this.siteTarget
      );
    });

    function onPlaceChanged() {
      var place = autocomplete.getPlace();
      map.setCenter(place.geometry.location);
      map.setZoom(14);
    }

    function setMapOnAll(map) {
      for (let i = 0; i < markers.length; i++) {
        markers[i].setMap(map);
      }
    }

    function hideMarkers() {
      setMapOnAll(null);
    }

    function drawMap(data, site_div) {
      const sortedData = uniquesSites(data);
      deleteMarkers();
      sortedData.forEach((object) => {
        if (object.count > 1) {
          draw_cluster(object);
        } else {
          draw_site(object, site_div);
        }
      });
    }

    function uniquesSites(sites) {
      const locationMap = new Map();
      sites.forEach((site) => {
        const key = `${site.lat}-${site.lng}`;
        if (!locationMap.has(key)) {
          locationMap.set(key, []);
        }
        locationMap.get(key).push(site);
      });

      const sortedSites = [];

      locationMap.forEach((siteList) => {
        if (siteList.length > 1) {
          const vipOrPrivateSites = siteList.filter((site) => {
            return (
              site.location_type === "vip" || site.location_type === "private"
            );
          });
          if (vipOrPrivateSites.length > 0) {
            sortedSites.push(...vipOrPrivateSites);
          }
        } else {
          sortedSites.push(...siteList);
        }
      });
      return sortedSites;
    }

    function findPin(site) {
      const evseStatus = site.evses_status;
      if (site.location_type === "private") {
        if (evseStatus.includes(1)) {
          // private available
          return prepare_pins(`/icons/${site.location_type}/1.png`);
        }
        if (evseStatus.includes(3)) {
          // private occupied
          return prepare_pins(`/icons/${site.location_type}/0.png`);
        }
        if (evseStatus.includes(7)) {
          // private reserved
          return prepare_pins(`/icons/${site.location_type}/7.png`);
        }
        if (evseStatus.includes(0)) {
          // private unavailable
          return prepare_pins(`/icons/${site.location_type}/3.png`);
        }
        if (evseStatus.includes(8)) {
          // private unavailable
          return prepare_pins(`/icons/${site.location_type}/3.png`);
        } else {
          return prepare_pins(`/icons/${site.location_type}/3.png`);
        }
      } else if (site.location_type === "vip") {
        if (evseStatus.includes(1)) {
          // vip available
          return prepare_pins(`/icons/${site.location_type}/1.png`);
        }
        if (evseStatus.includes(3)) {
          // vip occupied
          return prepare_pins(`/icons/${site.location_type}/0.png`);
        }
        if (evseStatus.includes(7)) {
          // vip reserved
          return prepare_pins(`/icons/${site.location_type}/7.png`);
        }
        if (evseStatus.includes(0)) {
          // vip unavailable
          return prepare_pins(`/icons/${site.location_type}/3.png`);
        }
        if (evseStatus.includes(8)) {
          // vip unavailable
          return prepare_pins(`/icons/${site.location_type}/3.png`);
        } else {
          return prepare_pins(`/icons/${site.location_type}/3.png`);
        }
      } else {
        if (evseStatus.includes(1)) {
          return prepare_pins("/icons/mapIcons/availLocation.png");
        }
        if (evseStatus.includes(3)) {
          return prepare_pins("/icons/mapIcons/occupiedLocation.png");
        }
        if (evseStatus.includes(7)) {
          return prepare_pins("/icons/mapIcons/reservedLocation.png");
        }
        if (evseStatus.includes(0)) {
          return prepare_pins("/icons/mapIcons/unavailLocation.png");
        }
        if (evseStatus.includes(8)) {
          return prepare_pins("/icons/mapIcons/unavailLocation.png");
        }
        if (evseStatus.includes(5)) {
          return prepare_pins("/icons/mapIcons/futureLocation.png");
        } else {
          return prepare_pins("/icons/mapIcons/unavailLocation.png");
        }
      }
    }

    function prepare_pins(src, width = 30, height = 30) {
      const privateAvailablePin = document.createElement("img");
      privateAvailablePin.src = src;
      privateAvailablePin.width = width;
      privateAvailablePin.height = height;
      return privateAvailablePin;
    }

    async function draw_site(site, site_div) {
      const latLng = new google.maps.LatLng(site.lat, site.lng);
      const { AdvancedMarkerElement, PinElement } =
        await google.maps.importLibrary("marker");
      const marker = new google.maps.marker.AdvancedMarkerElement({
        position: latLng,
        map: map,
        content: findPin(site),
      });
      const v3 = document.querySelector("#v3");
      marker.metadata = { id: site.id };

      markers.push(marker);
      google.maps.event.addListener(marker, "click", function () {
        getSiteInformations(marker.metadata.id, site_div);
      });
    }

    async function draw_cluster(cluster) {
      const latLng = new google.maps.LatLng(cluster.lat, cluster.lng);
      const clusterElement = document.createElement("div");
      clusterElement.style.width = "50px";
      clusterElement.style.height = "50px";
      clusterElement.style.backgroundColor = "rgba(93, 182, 184)";
      clusterElement.style.borderRadius = "50%";
      clusterElement.style.display = "flex";
      clusterElement.style.alignItems = "center";
      clusterElement.style.justifyContent = "center";
      clusterElement.style.color = "#FFFFFF";
      clusterElement.style.fontSize = "16px";
      clusterElement.innerText = cluster.count.toString();
      const { AdvancedMarkerElement, PinElement } =
        await google.maps.importLibrary("marker");
      const marker = new google.maps.marker.AdvancedMarkerElement({
        position: latLng,
        content: clusterElement,
        map: map,
      });

      markers.push(marker);
      google.maps.event.addListener(marker, "click", function () {
        deleteMarkers();
        map.panTo(marker.position);
        map.setZoom(map.zoom + 1);
      });
    }

    function deleteMarkers() {
      hideMarkers();
      markers = [];
    }

    function getMarkers(lat, lng, zoom, site_div) {
      deleteMarkers();

      let location_type = {
        vip: true,
        public: true,
        private: true,
      };

      let locationTypeParams = Object.keys(location_type)
        .map(
          (key) =>
            encodeURIComponent(`location_type[${key}]`) +
            "=" +
            encodeURIComponent(location_type[key])
        )
        .join("&");

      let url =
        "https://" +
        search_url +
        "/mobility_network/locations?lat=" +
        lat +
        "&lng=" +
        lng +
        "&zoom=" +
        zoom +
        "&" +
        locationTypeParams;

      var token = document
        .querySelector('meta[name="jwt-token"]')
        .getAttribute("content");

      fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          drawMap(data, site_div);
        });
    }

    function getSiteInformations(id, site_div) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "text/html");
      fetch(
        "https://" + window.location.host + "/mobility_network/locations/" + id,
        {
          headers: myHeaders,
        }
      )
        .then(function (response) {
          return response.text();
        })
        .then(function (data) {
          site_div.innerHTML = data;
        });
    }
  }
}
